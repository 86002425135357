import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 style={{
      marginTop: `2rem`,
      marginBottom: `2rem`,
      fontSize: `1rem`,
      color: `white`,
      position: `fixed`,
      top: `0`,
      width: `100%`,
      textAlign: `center`,
      fontFamily: `sans-serif`,
      fontWeight: `bold`,
      textShadow: `1px 1px 3px rgba(150, 150, 150, 1)`,
    }}>404: Not Found</h1>
  </Layout>
)

export default NotFoundPage
